var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('transportationGlobalFee.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('transportationGlobalFee.pageTitle'),
                href: '/admin/finance/transportation-fees'
            },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('table',{staticClass:"tw-w-full tw-mb-5"},[_c('thead',[_c('tr',[_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2 tw-w-4"},[_vm._v(" "+_vm._s(_vm.$t('transportationGlobalFee.no'))+" ")]),_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.$t( 'transportationGlobalFee.serviceType' ))+" ")]),_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.$t( 'transportationGlobalFee.numPackageFrom' ))+" ")]),_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.$t( 'transportationGlobalFee.numPackageTo' ))+" ")]),_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.$t('transportationGlobalFee.currency'))+" ")]),_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.$t('transportationGlobalFee.amount'))+" ")]),_c('th',{staticClass:"tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"},[_vm._v(" "+_vm._s(_vm.$t('transportationGlobalFee.chargeType'))+" ")]),_c('th',{staticClass:"tw-w-8 tw-py-2 tw-px-1 tw-bg-gray-100"},[_c('a',{staticClass:"tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addRow.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-plus"})])])])]),_c('tbody',_vm._l((_vm.model.item_list),function(l,index){return _c('tr',{key:index,staticClass:"tw-border-b"},[_c('td',{staticClass:"tw-px-3 tw-py-2 tw-text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"tw-px-3 tw-py-2"},[_c('a-select',{staticClass:"tw-w-full",attrs:{"placeholder":_vm.$t(
                                            'transportationGlobalFee.serviceType'
                                        ),"options":_vm.serviceTypes},model:{value:(l.service_type_id),callback:function ($$v) {_vm.$set(l, "service_type_id", $$v)},expression:"l.service_type_id"}}),(
                                        _vm.errors.has(
                                            'item_list.' +
                                                index +
                                                '.service_type_id'
                                        )
                                    )?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first( 'item_list.' + index + '.service_type_id' ))+" ")]):_vm._e()],1),_c('td',{staticClass:"tw-px-3 tw-py-2 tw-w-48"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(l.num_package_from),expression:"l.num_package_from",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"type":"number","step":"any","placeholder":_vm.$t(
                                            'transportationGlobalFee.numPackageFrom'
                                        )},domProps:{"value":(l.num_package_from)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(l, "num_package_from", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                        _vm.errors.has(
                                            'item_list.' +
                                                index +
                                                '.num_package_from'
                                        )
                                    )?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first( 'item_list.' + index + '.num_package_from' ))+" ")]):_vm._e()]),_c('td',{staticClass:"tw-px-3 tw-py-2 tw-w-48"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(l.num_package_to),expression:"l.num_package_to",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"type":"number","step":"any","placeholder":_vm.$t(
                                            'transportationGlobalFee.numPackageTo'
                                        )},domProps:{"value":(l.num_package_to)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(l, "num_package_to", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                        _vm.errors.has(
                                            'item_list.' +
                                                index +
                                                '.num_package_to'
                                        )
                                    )?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first( 'item_list.' + index + '.num_package_to' ))+" ")]):_vm._e()]),_c('td',{staticClass:"tw-px-3 tw-py-2"},[_c('a-select',{staticClass:"tw-w-full",attrs:{"options":_vm.currencies,"placeholder":_vm.$t(
                                            'transportationGlobalFee.currency'
                                        )},model:{value:(l.currency_id),callback:function ($$v) {_vm.$set(l, "currency_id", _vm._n($$v))},expression:"l.currency_id"}})],1),_c('td',{staticClass:"tw-px-3 tw-py-2 tw-w-48"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(l.fee_amount),expression:"l.fee_amount",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"type":"number","step":"any","placeholder":_vm.$t('transportationGlobalFee.amount')},domProps:{"value":(l.fee_amount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(l, "fee_amount", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                        _vm.errors.has(
                                            'item_list.' +
                                                index +
                                                '.fee_amount'
                                        )
                                    )?_c('div',{staticClass:"tw-text-red-600 tw-text-xs"},[_vm._v(" "+_vm._s(_vm.errors.first( 'item_list.' + index + '.fee_amount' ))+" ")]):_vm._e()]),_c('td',{staticClass:"tw-px-3 tw-py-2 tw-w-48"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(l.charge_type),expression:"l.charge_type"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(l, "charge_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Fixed"}},[_vm._v("Fixed")]),_c('option',{attrs:{"value":"Per Package"}},[_vm._v("Per Package")])])]),_c('td',{staticClass:"tw-w-4 tw-px-3 tw-py-2 tw-text-center"},[_c('a',{staticClass:"tw-text-red-600",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeRow(index)}}},[_c('i',{staticClass:"fa fa-trash"})])])])}),0)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-flex tw-justify-end tw-space-x-3"},[_c('ts-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({
                                    name: 'transportation-global-fee'
                                })}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","outline":"","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('saveAddNew')))]),_c('ts-button',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }